<template>
  <el-tooltip effect="dark" placement="top" :content="content" :open-delay="openDelay">
    <span>{{ content | shortString }}</span>
  </el-tooltip>
</template>

<script>
const DefaultOpenDelay = 800;

export default {
  name: 'CameraSelectOptionTooltip',
  props: {
    content: { type: String, required: true },
    openDelay: { type: Number, default: DefaultOpenDelay }
  }
};
</script>
