<template>
  <el-select v-model="model" :placeholder="$tf('not_selected')" @clear="$emit('clear')" clearable>
    <el-option v-for="item in filteredItems" :label="$filters.shortString(item.name)" :value="item.id" :key="item.id">
      <camera-select-option-tooltip :content="item.name" />
    </el-option>
  </el-select>
</template>

<script>
import { Component, Vue, Watch } from 'vue-property-decorator';
import CameraSelectOptionTooltip from './camera-select-option-tooltip';

@Component({
  name: 'CameraSelect',
  props: {
    items: { type: Array, required: true },
    value: { type: Number, default: null },
    group: { type: Number, default: null }
  },
  components: { CameraSelectOptionTooltip }
})
export default class extends Vue {
  get model() {
    return this.value;
  }

  set model(value) {
    this.$emit('input', value || null);
  }

  get filteredItems() {
    return this.group != null ? this.items.filter(({ group }) => group === this.group) : this.items;
  }

  @Watch('group')
  handleGroupChange() {
    this.model = null;
  }
}
</script>
